// const url = "https://nodepune-gameppcoreml.mobiloitte.io";
const url = "http://43.204.140.189:1952/api/v1";


export const socketNotification = "";
export const baseUrl = "";

const user = `${url}/user`;
const admin = `${url}/admin`;

const ApiConfig = {
  // *** Login Flow Endpoints *** //
  userLogin: `${admin}/login`,
  forgotPassword: `${admin}/forgetPassword`,
  otpVerificationForChangePassword: `${admin}/otpVerificationForChangePassword`,
  changePassword:`${admin}/changePassword`,
  // resetPassword: `${admin}/resetPassword`,
  changePasswordForAdmin: `${admin}/changePasswordForAdmin`,
  // *** User Management Endpoints *** //
  getAllUsers: `${admin}/listUser`,
  getUserById: `${admin}/viewUser`,
  blockUser: `${admin}/blockUnblockUser`,
  unblockUser: `${admin}/blockUnblockUser`,
  // blockUnblock: `${admin}/blockUnblockUser`,
  deleteUser: `${admin}/deleteUser`,
  getDashboard: `${admin}/dashboard`,
  getTermsAndCondition: `${admin}/getTermsAndCondition`,
  editTermsAndCondition: `${admin}/editTermsAndCondition`,
  editPrivacyPolicy: `${admin}/editPrivacyPolicy`,
  getDrawingCountOfUser: `${admin}/getDrawingCountOfUser`,
  getDrawingCountOfUserById: `${admin}/getDrawingCountOfUserById`,
  getUserDrawingBookById: `${admin}/getUserDrawingBookById`,
  editBookAdmin: `${admin}/editBookAdmin`,
  createPages: `${admin}/createPages`,
  getBookPages: `${admin}/getBookPages`,
  deleteBook: `${admin}/deleteBook`,
  getPages: `${admin}/getPages`,
  addBookAdmin: `${admin}/addBookAdmin`,
  model3d: `${admin}/model3d`,
  editPage: `${admin}/editPage`,
  getMyProfile: `${admin}/getMyProfile`,
  editProfile: `${admin}/editProfile`,
  allBanner: `${url}/banner/get-all-banners`,
  editBanner: `${url}/banner/editBanner`,
  getSpecificDinosaur: `${admin}/getSpecificDinosaur`,
  getSpecificBook: `${admin}/getSpecificBook`,
  deleteSpecificDrawing: `${admin}/deleteSpecificDrawing`,
  deleteAllDrawing: `${admin}/deleteAll`,
  getBookDropDown:  `${admin}/getBookDropDown`,
  generateQR: `${admin}/generateQr`,
  deletePage: `${admin}/deletePage`,

  // upload 3d assets
  uploadAndroid: `${admin}/uploadAndroid`,
  uploadIOS: `${admin}/uploadIOS`,

  // get 3d assets
  getIosBookAssets: `${admin}/getIosBookAssets`,
  getAndroidBookAssets: `${admin}/getAndroidBookAssets`,
  getAndroidAssetByPageId:`${admin}/getAndroidAssetByPageId`,
  getIOSAssetByPageId:`${admin}/getIOSAssetByPageId`,

  
  //delet 3d assets
  deleteAndroidAssets: `${admin}/deleteAndroidAssets`,
  deleteIosAssets:`${admin}/deleteIosAssets`

};

export default ApiConfig;
