import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import DashboardLayout from "src/layouts/DashboardLayout";
import HomeLayout from "src/layouts/HomeLayout";
import LoginLayout from "src/layouts/LoginLayout";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/LogIn")),
  },

  {
    exact: true,
    path: "/dashboard",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard")),
  },
  {
    exact: true,
    path: "/drawing-board-management",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/DrawingBoardManagement/index")),
  },
  {
    exact: true,
    path: "/view-drawing-board",
    // guard: true,
    layout: DashboardLayout,  
    component: lazy(() => import("src/views/pages/DrawingBoardManagement/view")),
  },
 
  {
    exact: true,
    path: "/view-coloring-book",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ColoringBookManagement/view")),
  },
  {
    exact: true,
    path: "/page-edit",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ColoringBookManagement/pageEdit")),
  },
  {
    exact: true,
    path: "/edit-book-page",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ColoringBookManagement/EditBookPage")),
  },
  {
    exact: true,
    path: "/page-view",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ColoringBookManagement/pageView")),
  },
  {
    exact: true,
    path: "/view-image-data-record",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ImageDataRecord/view")),
  },

  {
    exact: true,
    path: "/coloring-book-management",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ColoringBookManagement/index")),
  },
  {
    exact: true,
    path: "/edit-new-book",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ColoringBookManagement/Edit")),
  },
  {
    exact: true,
    path: "/add-new-book",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ColoringBookManagement/add.js")),
  },
  {
    exact: true,
    path: "/edit-model3D",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ColoringBookManagement/editmodel3d.js")),
  },
  {
    exact: true,
    path: "/static-content-management",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/StaticContentManagement/index")),
  },
  {
    exact: true,
    path: "/view-static-content-management",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/StaticContentManagement/viewStatic")),
  },
  {
    exact: true,
    path: "/edit-static-content-management",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/StaticContentManagement/editStatic")),
  },
  {
    exact: true,
    path: "/assets-management",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/AssetsManagement/index")),
  },
  {
    exact: true,
    path: "/view-assets",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/AssetsManagement/ViewAssets")),
  },
  {
    exact: true,
    path: "/view-withdraw",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/AssetsManagement/viewWithdraw.js")),
  },
  {
    exact: true,
    path: "/view-transfer",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/AssetsManagement/viewTransfer.js")),
  },
  {
    exact: true,
    path: "/verify-otp",

    layout: LoginLayout,
    component: lazy(() =>
      import("src/views/auth/forget-password-link/OtpVerify")
    ),
  },
  {
    exact: true,
    path: "/reset-password",

    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/reset-password/index")),
  },
  {
    exact: true,
    path: "/change-password",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Change Password/Index")),
  },

  {
    exact: true,
    path: "/forget-password",

    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/forget-password/index")),
  },
  {
    exact: true,
    path: "/user-management",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/userManagement")),
  },
  {
    exact: true,
    path: "/view-user-details",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/userManagement/viewUserDetails.js")),
  },
  {
    exact: true,
    path: "/upload-assets",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ColoringBookManagement/upload")),
  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
